import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { maxHeight } from "@material-ui/system";

import { Divider, CardHeader, Button, Grid, Box } from "@material-ui/core";
import downloadImage from "../../src/assets/images/surplus_download.svg";
import uploadImage from "../../src/assets/images/surplus_upload.svg";
import { brandList } from "../../src/util/ConstantItem";
import CustomSurplusTextPart from "../../src/CustomClasses/CustomSurplusTextPart";
import BoxBottomSmalText from "../../src/CustomClasses/BoxBottomSmalText";
import PageFooterQueryText from "../../src/CustomClasses/PageFooterQueryText";
import FooterPart from "../../src/CustomClasses/FooterPart";
import StepsGuidencePart from "../../src/CustomClasses/StepsGuidencePart";
import SelectDropDown from "../../src/CommonComponents/SelectDropDown";
import ApiEndPoint from "../Apiservice/ApiName";
import StringConstant from "../../src/ConstFiles/StringConstant";
import AlertDailog from "../CommonComponents/AlertDialog";
import AllString from "../ConstFiles/StringConstant";
import callServer from "../Apiservice/ApiExecutor";
import { takeResponseParsingDecision } from "../Apiservice/ApiResponseHttpParser";
import LoadingDialog from "../CommonComponents/LoadingDialog";
import ReactS3 from "react-s3";
import { styles } from "../../src/styles/SurplusStyle";
import Header from "../header";

const defaultProps = {
  borderColor: "#ABB2B9",
  height: "320px"
};

const s3Config = {
  bucketName: "dealer-portal-doc",
  dirName: "bom" /* optional */,
  region: ApiEndPoint.region,
  accessKeyId: ApiEndPoint.accessKeyId,
  secretAccessKey: ApiEndPoint.secretAccessKey
};
var selectedDownloadedBrandList = [];
class SurplusStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBrandId: 0,
      selectedUploadBrandId: 0,
      selectedUploadedBrandList: "",
      isShowAlertDialog: false,
      fileDownloaded: false, //
      isShowLoading: false,
      isShowLoading: false,
      selectedBrandName: "",
      inventoryFilePath: "",
      titleMsg: "Alert"
    };
  }

  functionReturnSelecterValue = (value, selectorType) => {
    this.setState({ selectedBrandName: value });
    console.log("value- returned data--" + value);
    console.log("value- returned from selectorType--" + selectorType);

    var dataList = this.props.loginData.brandhList;
    for (var i = 0; i < dataList.length; i++) {
      if (dataList[i].brandId === value) {
        selectedDownloadedBrandList = [];
        selectedDownloadedBrandList.push(dataList[i]);
      }
    }

    if (selectorType === "DOWNLOAD") {
      this.setState({ selectedBrandId: value });
      // this.setState({ selectedUploadedBrandList: "" });
    } else if (selectorType === "UPLOAD") {
      this.setState({ selectedUploadBrandId: value });
      this.setState({ selectedUploadedBrandList: value });
    }
  };

  clickedNoMoreButton = () => {
    //alert("No More clicked");
  };
  render() {
    const { classes, loginData } = this.props;
    const { selectedBrand, isShowLoading, titleMsg } = this.state;

    return (
      <React.Fragment>
        <div>
          <Header
            logOutUser={this.props.logOutUser}
            loginData={this.props.loginData}
            selectedOption="Bom"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "200px",
              backgroundColor: "rgb(243,246,254)",
              marginTop: "60px"
            }}
          >
            <Box boxShadow={0} className={classes.parentCardStyle}>
              <CardHeader
                className={classes.cardHeaderStyle}
                titleTypographyProps={{ variant: "h6" }}
                title="Upload your BOM / Indent"
              />
              <Divider variant="middle" />
              <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                <Grid container spacing={5} className={classes.parentGrid}>
                  <Grid item xs>
                    <Box className={classes.boxStyle}>
                      <CustomSurplusTextPart
                        imagePath={uploadImage}
                        heading={StringConstant.downloadFormat}
                        subHeading={StringConstant.selectBrandhToDownloadBOM}
                      />
                      <SelectDropDown
                        //menuItem={brandList}
                        menuItem={loginData.brandhList}
                        returnValue={this.functionReturnSelecterValue}
                        selectorType={"DOWNLOAD"}
                      />

                      <Button
                        className={classes.button}
                        onClick={() => this.downloadFormatedFile()}
                      >
                        Download Format
                      </Button>
                      <BoxBottomSmalText
                        msg={StringConstant.brandSpecificExcell}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box
                      borderLeft={0.1}
                      {...defaultProps}
                      className={classes.boxStyle}
                    >
                      <CustomSurplusTextPart
                        imagePath={downloadImage}
                        heading={StringConstant.uploadFormat}
                        subHeading={StringConstant.selectBrandToUploadBOM}
                      />

                      <SelectDropDown
                        //menuItem={selectedDownloadedBrandList}
                        menuItem={loginData.brandhList}
                        returnValue={this.functionReturnSelecterValue}
                        selectorType={"UPLOAD"}
                      />
                      {this.state.selectedUploadedBrandList === "" ? (
                        <div>
                          <Button
                            className={classes.button}
                            disabled={!this.state.fileDownloaded}
                            //onClick={() => this.uploadClicked()}
                            style={{
                              color: `${
                                !this.state.fileDownloaded ? "#fff" : "#fff"
                              }`,
                              backgroundColor: `${
                                !this.state.fileDownloaded
                                  ? "#d7d7d7"
                                  : "#2fbea8"
                              }`
                            }}
                          >
                            Upload Format
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <input
                            type="file"
                            disabled={false}
                            accept=".xlsx"
                            id="inventoryFile"
                            className={classes.input_file}
                            onChange={this.selectFile}
                          />
                          <label
                            id="labelid"
                            disabled={false}
                            className={classes.custom_upload_label_button}
                            htmlFor="inventoryFile"
                          >
                            Upload Format
                          </label>
                        </div>
                      )}
                      <BoxBottomSmalText
                        msg={StringConstant.acceptDataOnlyExcellFormat}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <StepsGuidencePart
                      headerText={StringConstant.stepsBomHeader}
                      subStepsText={StringConstant.stepsBomSubText}
                    />
                  </Grid>
                </Grid>
              </div>
              <FooterPart
                isFromInventory={false}
                noMoreButtonClicked={this.clickedNoMoreButton}
              />
              <PageFooterQueryText msg={StringConstant.footerQueryText} />
            </Box>
          </div>
        </div>
        <AlertDailog
          isShowAlertDialog={this.state.isShowAlertDialog}
          onOkClickButton={() => this.setState({ isShowAlertDialog: false })}
          alertMsg={this.state.alertMsg}
          title={titleMsg}
        />
        <LoadingDialog showLoading={isShowLoading} />
      </React.Fragment>
    );
  }

  uploadClicked = () => {
    this.alertDialogOpen(AllString.selectBrand);
    return;
  };

  downloadFormatedFile = () => {
    if (this.state.selectedBrandId === 0) {
      this.setState({ titleMsg: "Alert" });
      this.alertDialogOpen(AllString.selectBrand);
      return;
    }
    window.open(
      ApiEndPoint.DOWNLOADURL +
        ApiEndPoint.BUCKETNAME +
        "/template/DealerBomFile.xlsx",
      "_blank"
    );
    this.setState({ fileDownloaded: true });
  };

  uploadInventory = () => {
    if (this.state.selectedUploadBrandId === 0) {
      this.setState({ titleMsg: "Alert" });
      this.alertDialogOpen(AllString.selectBrand);
      return;
    }
  };

  alertDialogOpen = msg => {
    this.setState({ isShowAlertDialog: true });
    this.setState({ alertMsg: msg });
  };

  selectFile = event => {
    const files = event;
    console.log("File is : ", files);

    if (event.target.files[0] !== undefined) {
      this.setState({ isShowLoading: true });

      var myFile = event.target.files[0];
      console.log("File name : ", myFile);

      var reader = new FileReader();
      reader.readAsDataURL(myFile);

      reader.onload = () => {
        var data = reader.result,
          base64 = data.replace(/^[^,]*,/, "");
        // var userId = this.state.loginResponse.userID;
        var userId = this.props.loginData.id;
        var request = {
          bucket_name: ApiEndPoint.BUCKETNAME,
          folder_name: ApiEndPoint.BOM_FOLDER,
          file_name:
            userId +
            "_" +
            this.state.selectedBrandName +
            "_Bom" +
            "_" +
            new Date().getTime() +
            ".xlsx",
          inputStream: base64
        };

        callServer(
          ApiEndPoint.uploadFile,
          JSON.stringify(request),
          "POST",
          apiResponse => {
            let responseData = takeResponseParsingDecision("POST", apiResponse);
            this.setState({ isShowLoading: false });
            console.log("ApiRes -- ", JSON.stringify(responseData));

            if (
              responseData === undefined ||
              responseData === "undefined" ||
              responseData.foldername == ""
            ) {
              this.setState({ titleMsg: "Alert" });
              this.alertDialogOpen(AllString.problemWithFileUpload);
              return;
            }
            this.setState({ titleMsg: AllString.fileSuccessfullyUpload });
            this.alertDialogOpen(AllString.resultWillBeSentOnEmail);
          }
        );
      };
      reader.onerror = function(error) {
        this.setState({ showLoader: false });
        console.log("Error: base64 ", error);
      };
      // Reset value after successfull upload
      event.target.value = null;
    }
  };
}

export default withStyles(styles)(SurplusStock);
