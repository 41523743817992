import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import SurplusStock from "../pages/SurplusStock";
import { Route, Redirect } from "react-router-dom";
import Header from "../../src/header";

const BASE_CONTAINER_TOP_MARGIN = 2;
const DRAWER_WIDTH = 192;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
    // width: `calc(100% - ${BASE_CONTAINER_TOP_MARGIN}px)`,
    //  height: `calc(10% - ${BASE_CONTAINER_TOP_MARGIN}px)`
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#f3f6fe"
    //padding: theme.spacing.unit * 3
  }
}));

function Dashboard() {
  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          //backgroundColor: "#FD1D77",
          backgroundColor: "#e9eefd",
          // backgroundColor: "rgb(243,246,254)",
          height: "100vh",
          width: "1000vh"
        }}
      >
        <Header
          //logOutUser={this.props.logOutUser}
          //employerObj={this.props.employerObj}
          selectedOption="Add Employer"
        />
        <div
          style={{
            marginLeft: DRAWER_WIDTH,
            // backgroundColor: "rgb(243,246,254)",
            backgroundColor: "#edf1fd",
            height: "100vh",
            width: "1000vh",
            borderRadius: "5px",
            transition: "0.3s",
            display: "flex",
            borderColor: "#eef0f2",
            // border: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
            border: "10px solid rgb(237,241,253)"
          }}
        >
          <SurplusStock employerObj={"this.props.employerObj"} />
        </div>
      </div>
    </React.Fragment>
  );
}
export default Dashboard;
