import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  AppBar,
  Toolbar,
  MenuItem,
  MenuList,
  Divider,
  Typography,
  Hidden,
  Button,
  IconButton
} from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link, Redirect } from "react-router-dom";

const drawerWidth = 190;
const ITEM_HEIGHT = 20;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  titleTxt: {
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#241d41"
  },
  userName: {
    fontFamily: "Quicksand",
    fontSize: 14,
    fontWeight: 500,
    color: "#777777"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  //toolbar: theme.mixins.toolbar,
  toolbar: {
    backgroundColor: "#ffffff",
    minHeight: 64,
    display: "flex",
    justifyContent: "space-between"
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const options = ["Change Password", "Logout"];
class Layout extends Component {
  state = {
    mobileOpen: false,
    surplusstock: false,
    billofmaterial: false,
    dashboard: false,
    changePwd: false,
    drawerOpen: false,
    isLogout: false,
    anchorEl: null
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, children } = this.props;
    const {
      mobileOpen,
      changePwd,
      surplusstock,
      dashboard,
      billofmaterial,
      anchorEl
    } = this.state;
    const drawer = (
      <div>
        <Hidden>
          <div className={classes.toolbar} />
        </Hidden>
        <MenuList>
          <MenuItem component={Link} to="/surplus-stock">
            Surplus Stock / Non-moving Inventory
          </MenuItem>
          <MenuItem component={Link} to="/bill-of-material">
            Bill of Material ( BOM) / Indent
          </MenuItem>
        </MenuList>
      </div>
    );

    const open = Boolean(anchorEl);
    if (changePwd) {
      return (
        <Redirect
          to={{
            pathname: "/change-pwd"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }
    if (surplusstock) {
      return (
        <Redirect
          to={{
            pathname: "/surplus-stock"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }

    if (dashboard) {
      return (
        <Redirect
          to={{
            pathname: "/surplus-stock"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }
    if (billofmaterial) {
      return (
        <Redirect
          to={{
            pathname: "/bill-of-material"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <span className={classes.titleTxt} variant="h6"></span>
            <div>
              <span className={classes.userName}>
                {/*this.props.employerObj.employerName*/}
                Majid Khan
              </span>

              <IconButton
                aria-label="More"
                aria-owns={open ? "long-menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <img
                  className={classes.arrowImg}
                  src={require("../../src/assets/images/down-arrow.png")}
                  //alt="down-arrow"
                />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 200
                  }
                }}
              >
                {options.map(option => (
                  <MenuItem
                    key={option}
                    onClick={() => this.handleMenu(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              // anchor={theme.direction === "rtl" ? "right" : "left"}
              anchor="left"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}
Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
