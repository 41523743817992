export const downloadHistoryColumn = [
  {
    name: "date",
    label: "Date"
  },
  { name: "time", label: "Time" },
  {
    name: "brand",
    label: "Brand"
  },
  {
    name: "action",
    label: ""
  },
  {
    name: "action",
    label: ""
  }
];

export const fileHistoryColumn = [
  {
    name: "date",
    label: "Date"
  },
  {
    name: "time",
    label: "Time"
  },
 
  {
    name: "brand",
    label: "Brand"
  },
  {
    name: "status",
    label: "Status"
  },
  {
    name: "action",
    label: ""
  },
  {
    name: "action",
    label: ""
  }
];