import React from "react";
import { Redirect } from "react-router-dom";
import Login from "./Login";
const Home = () => {
  return (
    <div style={{ padding: "24px" }}>
      <h1>This is Home</h1>
      <Redirect to="/login" />

      <br />
      <br />
    </div>
  );
};

export default Home;
