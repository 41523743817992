import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AllString from "../ConstFiles/StringConstant";
//const toolbar = props => (

class AlertDialog extends React.Component {
  state = {
    isDialogOpen: false
  };
  componentDidMount() {
    console.log("this", this.props);
  }
  render() {
    const isDialogOpen = this.props.isShowAlertDialog;
    const title = this.props.title;
    return (
      <div>
        <Dialog
          open={isDialogOpen}
          titleStyle={{ textAlign: "center" }}
          //onClose={this.handleClose}
          //fullWidth="true"
        >
          <DialogTitle>
            <h
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {title !== undefined || title !== "undefined" ? title : "Alert!!"}
            </h>
          </DialogTitle>
          <DialogContent>
            <h
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItem: "center"
              }}
            >
              <DialogContentText>{this.props.alertMsg}</DialogContentText>
            </h>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.onOkClickButton}
              autofocus
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
