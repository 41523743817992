import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Login from "../src/pages/Login";
import DownloadHistory from "../src/pages/DownloadHistory";
import TestLogin from "../src/Test/LoginTest";
import SurplusStock from "../src/pages/SurplusStock";
import TestViewer from "../src/Test/TestViewer";
import HistorySurplusStock from '../src/CustomClasses/HistorySurplusStock'
import TestFile from '../src/Test/TestViewer'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
