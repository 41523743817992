import React, { useState, useEffect } from "react";
import { Route, Redirect, BrowserRouter, Switch } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import "./App.css";
import Login from "../src/pages/Login";
import TestLogin from "../src/Test/LoginTest";
import Home from "../src/pages/Home";
import Dashboard from "../src/pages/Dashboard";
import SurplusStock from "../src/pages/SurplusStock";
import BillOfMaterial from "../src/pages/BillOfMaterial";
import DownloadHistory from "../src/pages/DownloadHistory";
import History from '../src/pages/History'
import Header from "../src/header";
import Layout from "../src/Layout";

const Authorization = (WrappedComponent, employerObj, logOutUser) => {
  return class WithAuthorization extends React.Component {
    render() {
      console.log(
        "in app class dealerPortalData ==" + JSON.stringify(this.props)
      );

      if (localStorage.getItem("dealerPortalData") != undefined) {
        //("The name of the component is : ", WrappedComponent);
        return (
          <WrappedComponent
            {...this.props}
            loginData={JSON.parse(localStorage.getItem("dealerPortalData"))}
            logOutUser={logOutUser}
          />
        );
      } else {
        return <Redirect to="/" />;
      }
    }
  };
};

function App(props) {
  //const [logOutUser, setLogOutUser] = useState(false);
  const [loginData, setLoginData] = useState({});

  const logInUser = loginData => {
    console.log("log in user ran man : ", loginData);
    setLoginData(loginData);

    localStorage.setItem("dealerPortalData", JSON.stringify(loginData));
  };

  const logOutUser = () => {
    //("logout ran");
    setLoginData({});
    localStorage.removeItem("dealerPortalData");
    //window.location.reload();
    return (
      <div>
        <Route
          exact
          path="/"
          render={routeProps => (
            <Login
              {...routeProps}
              logInUser={logInUser}
              logOutUser={logOutUser}
            />
          )}
        />
      </div>
    );
  };

  return (
    <div>
      {/*<Route exact path="/" render={() => <div>Home</div>} />*/}
      <Route
        exact
        path="/"
        render={routeProps => (
          <Login
            {...routeProps}
            logInUser={logInUser}
            logOutUser={logOutUser}
          />
        )}
      />

      {/*<Route
        path="/login"
        render={routeProps => (
          <Login
            {...routeProps}
            logInUser={logInUser}
            logOutUser={logOutUser}
          />
        )}
      />*/}

      <Route
        path="/surplus-stock"
        component={Authorization(SurplusStock, loginData, logOutUser)}
      />
      <Route
        path="/bill-of-material"
        component={Authorization(BillOfMaterial, loginData, logOutUser)}
      />
      <Route
        path="/your-results"
        component={Authorization(DownloadHistory, loginData, logOutUser)}
      />
       <Route
        path="/history"
        component={Authorization(History, loginData, logOutUser)}
      />
    </div>
  );
}

export default App;
