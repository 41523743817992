import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  CardHeader,
  Divider,
  Box,
  Grid,
  Drawer
} from "@material-ui/core";
import LoadingDialog from "../CommonComponents/LoadingDialog";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import ApiEndPoint from "../Apiservice/ApiName";
import { downloadHistoryColumn } from "../util/TableColumn";
import PageFooterQueryText from "../CustomClasses/PageFooterQueryText";
import StringConstant from "../ConstFiles/StringConstant";
import AlertDailog from "../CommonComponents/AlertDialog";
import callServer from "../Apiservice/ApiExecutor";
import { takeResponseParsingDecision } from "../Apiservice/ApiResponseHttpParser";
import AllString from "../ConstFiles/StringConstant";
import { logger } from "../util/CommonFunction";
import Header from "../header";
import ExcellViewer from '../CustomClasses/ExcellViewer'

const StyledTableCell = withStyles(theme => ({
  head: {
    //backgroundColor: "#4d54ac",
    backgroundColor: "rgba(0,0,255,0.22)",
    fontFamily: "Montserrat",
    fontSize: "17px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    //color: theme.palette.common.black,
    fontSize: "20px",
    // opacity: 0.22,
    height: 10
  },
  body: {
    fontSize: 1
  }
}))(TableCell);

/*function createData(carrierLegalName, carrierId) {
  return { carrierLegalName, carrierId };
} */

const tableBodyData_ = [
  {
    date: "23/05/2020",
    time: "12.45 PM",
    brand: "Cummins India Ltd",
    downloadLink: ""
  },
  {
    date: "24/05/2020",
    time: "11.45 PM",
    brand: "Cummins India Ltd",
    downloadLink: ""
  },
  {
    date: "25/05/2020",
    time: "10.45 PM",
    brand: "Cummins India Ltd",
    downloadLink: ""
  }
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  typography_style: {
    marginTop: "40%",
    padding: 15
  },
  parentCardStyle: {
    width: "85vw",
    height: "90vh",
    display: "block",
    marginRight: "65%",
    transitionDuration: "0.39",
    borderRadius: 1,
    // backgroundColor: "#FFFFFF"
    backgroundColor: "rgb(243,246,254)"
    //color: theme.palette.primary.contrastText
  },
  cardHeaderStyle: {
    width: "593px",
    height: "27px",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 600,
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  parentGrid: {
    marginTop: 12,
    backgroundColor: "rgb(243,246,254)"
    //  marginLeft: 10,
    //  marginRight: 20
  },
  boxStyle: {
    paddingLeft: "29px",
    paddingRight: "29px",
    borderRadius: 5
  },
  rowCellStyle: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#1b0c39"
  }
});


const file = 'http://file-examples-com.github.io/uploads/2017/02/file_example_XLSX_10'

//var tableBodyData = [];
export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowAlertDialog, setShowAlert] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [alertMsg, setAlertMsg] = useState("");
  const [open, setopen] = useState(false);
  const [tempUrl, setTempUrl] = useState("");

  useEffect(() => {
    fetchDownloadHistory();
  }, []);

  // Common function for open alert dialog from all function within a class..
  const alertDialogOpen = msg => {
    setShowAlert(true);
    setAlertMsg(msg);
  };

  const fetchDownloadHistory = () => {
    var payload = {
      requestType: "DOWNLOAD_HISTORY",
      userId: props.loginData.id
      //userId: 1
    };
    setIsShowLoading(true);
    callServer(
      ApiEndPoint.dealerRequest,
      JSON.stringify(payload),
      "POST",
      apiResponse => {
        let responseData = takeResponseParsingDecision("POST", apiResponse);
        logger("ApiRes -- ", JSON.stringify(responseData));
        setIsShowLoading(false);
        if (responseData === undefined || responseData === null) {
          alertDialogOpen(AllString.someWentWrong);
          return;
        }

        if (responseData.statusCode !== 200) {
          alertDialogOpen(responseData.message);
          return;
        }

        setHistoryList(responseData.data);
        console.log("responseData.data.length---" + responseData.data.length);
        setRowsPerPage(responseData.data.length);
      }
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const clickedOnDownload = rowData => {
    console.log("Downloaded Link---->" + rowData.filePath);
    window.open(rowData.filePath, "_blank");
  };

  const onError =(e)=> {
    logger.logError(e, 'error in file-viewer');
  }
  const clickedOnView = rowData => {
    console.log("clickedOnView Your Result Link---->" + rowData.filePath);
    //setTempUrl(file)
    setTempUrl(rowData.filePath)
    setopen(true)
  };

  return (
    <React.Fragment>
      <div>
        <Header
          logOutUser={props.logOutUser}
          loginData={props.loginData}
          selectedOption="Download History"
        />
        <div
          style={{
            flexDirection: "row",
            marginLeft: "200px",
            backgroundColor: "rgb(243,246,254)",
            marginTop: "60px"
          }}
        >
          <Box boxShadow={0} className={classes.parentCardStyle}>
            <CardHeader
              className={classes.cardHeaderStyle}
              titleTypographyProps={{ variant: "h6" }}
              title="Download your Results"
            />
            <Divider variant="middle" />
            <div style={{ paddingLeft: 12, paddingRight: 12 }}>
              <Grid container spacing={3} className={classes.parentGrid}>
                <Grid item xs>
                  <div className={classes.boxStyle}>
                    <Box boxShadow={1}>
                      <TableContainer className={classes.container}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {downloadHistoryColumn.map(column => (
                                <StyledTableCell
                                  key={column.name}
                                  align={column.align}
                                  style={{
                                    width: "20px"
                                  }}
                                >
                                  {column.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {historyList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(row => {
                                return (
                                  <TableRow
                                    selectable={true}
                                    hover
                                    role="checkbox"
                                    //tabIndex={-1}
                                    key={row.date}
                                    hover
                                  >
                                    <TableCell>
                                      <h className={classes.rowCellStyle}>
                                        {row.date}
                                      </h>
                                    </TableCell>
                                    <TableCell>
                                      <h className={classes.rowCellStyle}>
                                        {row.time}
                                      </h>
                                    </TableCell>
                                    <TableCell>
                                      <h className={classes.rowCellStyle}>
                                        {row.brandName}
                                      </h>
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#2fbea8",
                                          fontFamily: "Montserrat",
                                          fontSize: "13px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          letterSpacing: "normal"
                                        }}
                                        href="#"
                                        onClick={() => clickedOnView(row)}
                                      >
                                        View
                                      </a>
                                      {/*viewUI*/}
                                    </TableCell>
                                    
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#2fbea8",
                                          fontFamily: "Montserrat",
                                          fontSize: "13px",
                                          fontWeight: 500,
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          letterSpacing: "normal"
                                        }}
                                        href="#"
                                        onClick={() => clickedOnDownload(row)}
                                      >
                                        Download
                                      </a>
                                      {/*viewUI*/}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/*<TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableBodyData_.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />*/}
                    </Box>
                  </div>
                </Grid>
              </Grid>

              <PageFooterQueryText msg={StringConstant.footerQueryText} />
            </div>
          </Box>
        </div>
      </div>
      <AlertDailog
        isShowAlertDialog={isShowAlertDialog}
        onOkClickButton={() => setShowAlert(false)}
        alertMsg={alertMsg}
        title={AllString.alert}
      />
      <LoadingDialog showLoading={isShowLoading} />
      <Drawer
        anchor={"right"}
        open={open}
        onClose={() => {
          setopen(false);
        }}
      >
        <ExcellViewer
          setopen={setopen}
          url={tempUrl}
        />
      </Drawer>
    </React.Fragment>
  );
}
