import React, { useState } from "react";
import { CardMedia, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import howItWorkImage from "../assets/images/how_it_work.svg";
import RightArrowImage from "../assets/images/icon_right_arrow.png";

const useStyles = makeStyles({
  bottomBoxTextColor: {
    // width: "84px",
    height: "19px",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  media: {
    width: "65px",
    height: "15px",
    marginLeft: "10px",
    marginRight: "10px",
    opacity: 0.8
  },
  buttonKnowMore: {
    width: "196px",
    height: "66px",
    color: "#fff",
    fontFamily: "Poppins",
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    fontSize: "18px",
    textTransform: "none"
  }
});

const FooterPart = props => {
  const classes = useStyles();

  const noMoreButtonClicked = () => {
    console.log("No more clicked");
    props.noMoreButtonClicked();
  };

  return (
    <div
      style={{
        width: "1110px",
        height: "132px",
        marginTop: "31px",
        marginLeft: "20px",
        marginRight: "20px",
        borderRadius: "10px",
        objectFit: "contain",
        backgroundImage: `url(${howItWorkImage})`
      }}
    >
      <div
        style={{
          marginLeft: "179px",
          paddingTop: "30px"
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <div>
              <Typography
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontWeight: 600,
                  fontSize: "20px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.3,
                  letterSpacing: "normal",
                  fontFamily: "Montserrat",
                  paddingBottom: "18px"
                }}
              >
                How it Works
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography className={classes.bottomBoxTextColor}>
                Login
              </Typography>

              <CardMedia className={classes.media} image={RightArrowImage} />
              <Typography className={classes.bottomBoxTextColor}>
                Download
              </Typography>
              <CardMedia className={classes.media} image={RightArrowImage} />
              <Typography className={classes.bottomBoxTextColor}>
                Upload
              </Typography>
              <CardMedia className={classes.media} image={RightArrowImage} />
              <Typography className={classes.bottomBoxTextColor}>
                {props.isFromInventory ? "Start Selling" : "Get Result"}
              </Typography>
            </div>
          </div>
          {/*<div
            style={{
              marginLeft: "231px"
            }}
          >
            <Button
              className={classes.buttonKnowMore}
              onClick={noMoreButtonClicked}
            >
              know More
            </Button>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default FooterPart;
