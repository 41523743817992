import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  TextField,
  Button,
  Grid,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import tph from "../../src/assets/images/tph.png";
import loginLine from "../../src/assets/images/loginline.svg";
import otpLine from "../../src/assets/images/otpLine.svg";
import { Route, Redirect } from "react-router-dom";
import Validator from "../util/validator";
import AlertDailog from "../CommonComponents/AlertDialog";
import AllString from "../ConstFiles/StringConstant";
import callServer from "../Apiservice/ApiExecutor";
import LoadingDialog from "../CommonComponents/LoadingDialog";
import { validateAllInputFields } from "../util/validator";
import ApiEndPoint from "../Apiservice/ApiName";
import { takeResponseParsingDecision } from "../Apiservice/ApiResponseHttpParser";
import App from "../App";
import "../../src/styles/loginstyle.css";
import { logger } from "../util/CommonFunction";

const useStyles = makeStyles({
  root_grid: {
    flexGrow: 1,
    marginTop: "40px"
  },
  logincard: {
    width: "590px",
    height: "610px",
    borderRadius: "20px",
    // marginLeft: "450px",
    backgroundColor: "#ffffff"
  },
  media: {
    width: "250px",
    height: "54px",
    marginTop: "94px",
    marginLeft: "173px",
    marginRight: "173px",
    objectFit: "contain"
  },
  welcome_back: {
    width: "170px",
    height: "27px",
    marginLeft: "220px",
    marginRight: "204px",
    marginTop: "20px",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  login_account: {
    width: "341px",
    height: "37px",
    marginLeft: "146px",
    marginRight: "126px",
    marginTop: "6px",
    fontFamily: "Montserrat",
    fontSize: "30px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  otpnumber_typography: {
    width: "291px",
    height: "24px",
    marginLeft: "180px",
    marginRight: "149px",
    marginTop: "25px",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    color: "#241d41"
  },
  line: {
    width: "92px",
    height: "6px",
    marginLeft: "260px",
    marginRight: "253px",
    objectFit: "contain"
  },
  textField: {
    width: "340px",
    marginLeft: "123px",
    marginRight: "127px",
    marginTop: "31px",
    borderRadius: "8px",
    boxShadow: "inset 0 0 2px 0 #20dada",
    border: "solid 0.5px #2fbea8",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    }
  },

  button: {
    marginTop: 20,
    width: "340px",
    height: "50px",
    marginLeft: "123px",
    marginRight: "127px",
    fontWeight: 600,
    borderRadius: "8px",
    color: "#FFFFFF",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    fontFamily: "poppins",
    fontSize: "20px",
    textTransform: "none"
  },
  input: {
    height: 43
  },
  exclusive_service: {
    height: "40px",
    fontSize: "30px",
    paddingLeft: "10px",
    paddingRight: "20px",
    fontWeight: 500,
    color: "#241d41",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#FFFFFF"
  },
  delaer: {
    height: "40px",
    fontSize: "50px",
    marginTop: "12px",
    paddingRight: "20px",
    fontWeight: 500,
    color: "#ffffff",
    fontWeight: "bold",
    lineHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal"
  },
  button_txt: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 550,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  paper: {
    // padding: theme.spacing(32),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    backgroundColor: "yellow"
  },

  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)"
    // gridGap: theme.spacing(3)
  }
});

export default function Login(props) {
  const [values, setValues] = useState({
    userId: "",
    password: "",
    otpCode: ""
  });
  const [otpResendTimerValue, setOtpCounter] = useState(30);
  const [isOtpTimerStart, setStartOtpTimer] = useState(false);
  const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [loginResObj, setLoginResObj] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [loginDataResponse, setLoginDataresponse] = useState({});

  const classes = useStyles();

  // Common function for open alert dialog from all function within a class..
  const alertDialogOpen = msg => {
    setIsShowAlertDialog(true);
    setAlertMsg(msg);
  };

  const makeServerCall = () => {
    // Validate UserId/Password fields with blank
    var msgMy = validateAllInputFields(values.userId, values.password);
    if (msgMy.length > 0) {
      alertDialogOpen(msgMy);
      return;
    }

    if (isOtpSent && values.otpCode.length === 0) {
      alertDialogOpen(AllString.enterOtp);
      return;
    }

    var payload;
    if (isOtpSent) {
      payload = {
        requestType: "VERIFY_OTP",
        mobileNumber: loginResObj.data.mobileNumber,
        otpCode: values.otpCode
      };
    } else {
      payload = {
        requestType: "LOGIN",
        loginId: values.userId,
        password: values.password
      };
    }
    callServer(
      ApiEndPoint.dealerRequest,
      JSON.stringify(payload),
      "POST",
      apiResponse => {
        let responseData = takeResponseParsingDecision("POST", apiResponse);
        logger("ApiRes -- ", JSON.stringify(responseData));
        setIsShowLoading(false);
        if (responseData === undefined || responseData === null) {
          alertDialogOpen(AllString.someWentWrong);
          return;
        }

        setIsShowLoading(false);
        if (responseData.statusCode !== 200) {
          alertDialogOpen(responseData.message);
          return;
        } else {
          if (payload.requestType === "LOGIN") {
            // Add Temp list
            if (responseData.data.brandhList === undefined) {
              responseData.data.brandhList = [
                {
                  brandId: 1,
                  brandName: "Samsung"
                },
                {
                  brandId: 2,
                  brandName: "Sony"
                }
              ];
            }

            setLoginResObj(responseData);
            setIsOtpSent(true);
            setStartOtpTimer(true);
          } else if (payload.requestType === "VERIFY_OTP") {
            setRedirectToDashboard(true);
          }
        }
      }
    );

    setIsShowLoading(isShowLoading => !isShowLoading);
    logger("Logging res-->>", JSON.stringify(loginResObj));
    //setLoginShowFunction(isLoginScreenShow => !isLoginScreenShow);
  };
  // Execute below code when clicked on send otp button
  const sendOtpClick = () => {
    // makeServerCall();
    setIsOtpSent(true);
    setStartOtpTimer(true);
  };

  // Execute below code when clicked on Submit button
  const submitClick = () => {
    // makeServerCall();
    //setRedirectToDashboard(true);
    var data = {
      statusCode: 200,
      message: "OTP Sent to your registered mobile number",
      data: {
        id: 1,
        loginId: "majid",
        firstName: "Majid Khan",
        lastName: "Khan",
        mobileNumber: "8109536446",
        emailId: "mazidkhan008@gmail.com",
        address: "Dhanori, Pune",
        pinCode: "411015",
        dealerCompanyName: "Etafi",
        dealerBrand: null,
        countryId: "101",
        stateId: "14",
        cityId: "12",
        brandhList: [
          { brandId: 1, brandName: "Siemens" },
          { brandId: 2, brandName: "Samsung" }
        ]
      }
    };
    //loginResObj
    var brandList = data.data.brandhList;
    data.data.brandhList = brandList;
    setLoginDataresponse(data.data);
    console.log("Final  submit in data == " + JSON.stringify(data.data));
    props.logInUser(data.data);

    setRedirectToDashboard(false);
  };

  if (redirectToDashboard) {
    return (
      <Redirect
        to={{
          pathname: "/surplus-stock",
          state: { loginResponseObj: loginDataResponse }
        }}
        push
      />
    );
  }

  // Otp countDown timer
  if (isOtpTimerStart && otpResendTimerValue > 0) {
    setTimeout(() => setOtpCounter(otpResendTimerValue - 1), 1000);
  }

  // Resend OTP Clicked function
  const resendOTPClick = () => {
    setStartOtpTimer(true);
    setOtpCounter(30);
  };

  if (redirectToDashboard) {
    var data = {
      statusCode: 200,
      message: "OTP Sent to your registered mobile number",
      data: {
        id: 1,
        loginId: "majid",
        firstName: "Majid Khan",
        lastName: "Khan",
        mobileNumber: "8109536446",
        emailId: "mazidkhan008@gmail.com",
        address: "Dhanori, Pune",
        pinCode: "411015",
        dealerCompanyName: "Etafi",
        dealerBrand: null,
        countryId: "101",
        stateId: "14",
        cityId: "12",
        brandhList: [
          { brandId: 1, brandName: "Siemens" },
          { brandId: 2, brandName: "Samsung" }
        ]
      }
    };
    //loginResObj
    var brandList = data.data.brandhList;
    data.data.brandhList = brandList;
    console.log("Final data == " + JSON.stringify(brandList));
    setLoginDataresponse(data.data);
    props.logInUser(loginDataResponse);
    {
      /*return <App dataObject={data} />;*/
    }
  }
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <div
      className="parent-div"
      style={{
        backgroundImage:
          "url(" + `${require("../../src/assets/images/dark.png")}` + ")"
      }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
        spacing={3}
        spacing={0}
        style={{ marginTop: "5%", marginBottom: "50px" }}
      >
        <div className={classes.container}>
          <div style={{ gridColumnEnd: "span 6" }}>
            <div style={{ marginLeft: "134px" }}>
              <label className={classes.exclusive_service}>
                An exclusive service by TPH
              </label>
              <div style={{ marginTop: 20 }}></div>
              <label className={classes.exclusive_service}>
                to Manage & Liquidate
              </label>
              <div style={{ marginTop: 20 }}></div>
              <label className={classes.exclusive_service}>
                Surplus Stock of
              </label>
              <Typography className={classes.delaer}>
                Dealers & Distributers
              </Typography>
            </div>
          </div>
          <div style={{ gridColumnEnd: "span 4" }}>
            <Card className={classes.logincard}>
              <CardMedia
                className={classes.media}
                image={tph}
                title="The Purchase House"
              />
              <CardContent>
                <Typography className={classes.welcome_back} component="h">
                  Welcome Back
                </Typography>

                <Typography className={classes.login_account}>
                  Login to your account
                </Typography>
              </CardContent>
              <CardMedia
                className={classes.line}
                image={!isOtpSent ? loginLine : otpLine}
                title="The Purchase House"
              />
              <form className={classes.root} noValidate autoComplete="off">
                {!isOtpSent ? (
                  <div>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      id="outlined-basic"
                      placeholder="User ID"
                      variant="outlined"
                      color="secondary"
                      value={values.userId}
                      onChange={handleChange("userId")}
                      autoFocus
                      InputProps={{
                        className: classes.input
                      }}
                    />
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Password"
                      variant="outlined"
                      value={values.password}
                      onChange={handleChange("password")}
                      className={classes.textField}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                    <Button
                      className={classes.button}
                      variant="contained"
                      onClick={sendOtpClick}
                      //onClick={() => setCount(count + 1)}
                    >
                      <span className={classes.button_txt}>Get OTP</span>
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.otpnumber_typography}>
                      OTP successfully sent to +91
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Enter OTP"
                      variant="outlined"
                      className={classes.textField}
                      value={values.otpCode}
                      onChange={handleChange("otpCode")}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                    <Button
                      className={classes.button}
                      variant="contained"
                      onClick={submitClick}
                      //onClick={() => setCount(count + 1)}
                    >
                      <span className={classes.button_txt}>Submit</span>
                    </Button>
                    {otpResendTimerValue === 0 ? (
                      <Button
                        className={classes.button}
                        variant="contained"
                        onClick={resendOTPClick}
                      >
                        <span className={classes.button_txt}>Resend OTP</span>
                      </Button>
                    ) : (
                      <div></div>
                    )}

                    {otpResendTimerValue !== 0 ? (
                      <p className="login_timer">
                        Resend OTP in {otpResendTimerValue} Sec
                      </p>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </form>
            </Card>
          </div>
        </div>
      </Grid>
      <AlertDailog
        isShowAlertDialog={isShowAlertDialog}
        onOkClickButton={() => setIsShowAlertDialog(false)}
        alertMsg={alertMsg}
      />
      <LoadingDialog showLoading={isShowLoading} />
    </div>
  );
}
