import React, { useState } from "react";
import { CardMedia, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  stepto_upload_surplus: {
    width: "282px",
    height: "52px",
    paddingTop: "24px",
    marginLeft: "25px",
    paddingRight: "30px",
    fontFamily: "Montserrat",
    fontSize: "17px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 1.44,
    color: "#241d41"
  },
  stepto_upload_subtext: {
    width: "282px",
    height: "42px",
    opacity: 0.8,
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#241d41"
  }
});

const StepsGuidencePart = props => {
  const classes = useStyles();

  return (
    <div
      style={{
        // backgroundColor: "#e9eefd",
        width: "300px",
        height: "350px",
        opacity: 2,
        // boxShadow:"0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
        boxShadow: "0 0 10px 5px rgba(72, 89, 102, 0.1)"
      }}
    >
      <div
        style={{
          backgroundColor: "#4d54ac",
          width: "300px",
          height: "350px",
          borderRadius: "6px",
          paddingTop: 6
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            width: "300px",
            height: "350px",
            boxShadow: "0 0 11px 2px rgba(72, 89, 102, 0.1)",
            borderRadius: "6px"
          }}
        >
          <div>
            <Typography className={classes.stepto_upload_surplus}>
              {props.headerText}
            </Typography>

            <div style={{ marginLeft: "21px", marginTop: "15px" }}>
              {props.subStepsText.map((name, index) => (
                <Typography className={classes.stepto_upload_subtext}>
                  {name}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsGuidencePart;
