export default {
  downloadFormat: "Download the BOM Format",
  downloadFormatInventory: "Download the Stock Format",
  uploadFormatInventory: "Upload your Stock",
  uploadFormat: "Upload Your BOM",
  selectBrandhToDownload:
    "Select your brand to download the inventory format to be filled up",
  selectBrandhToDownloadBOM:
    "Select your brand to download the BOM format to be filled up",
  selectBrandToUpload: "Select your Brand before uploading your Stock",
  selectBrandToUploadBOM: "Select your Brand before uploading your BOM",
  brandSpecificExcell:
    "A Brand Specific excel format will be downloaded on above click, which is to be filled up & upload it again",
  acceptDataOnlyExcellFormat: " We only accept data filled in our excel format",
  stepsHeader: "Steps to upload Surplus Stock / Excess Inventory",
  stepsBomHeader: "Steps to upload Bill of Material / Indent",
  footerQueryText:
    "For any query email us at dstock@thepurchasehouse.com or Just Call us on 8308354446",
  stepsSubText: [
    "- Select your brand",
    "- Download the specific format",
    "- Fill this specific format offline",
    "- Upload your stock in one click",
    "- Regularly update your stock"
  ],
  stepsBomSubText: [
    "- Select your brand",
    "- Download the specific format",
    "- Fill this specific format offline",
    "- Upload your BOM in one click",
    "- Click on Get results once ready"
  ],

  // Validation Related string
  enterUserID: "Enter User Id",
  enterPassword: "Enter Password",
  enterOtp: "Enter OTP",
  someWentWrong: "Something Went Wrong",
  selectBrand: "Please Select The Brand",
  fileSuccessfullyUpload: "File has been uploaded successfully",
  resultWillBeSentOnEmail:
    "It will take few minutes to gather the data & show you the results, we will intimate you once results are ready to download.",
  problemWithFileUpload: "There are some problem while uploading the file",
  success: "Success",
  alert: "Alert",

  // Registration String
  companyName: "Enter Company Name",
  contactPerson: "Enter Contact Person",
  emailId: "Enter Email Id",
  mobileNumber: "Enter Mobile Number",
  brand: "Enter Brand Name",
  enq_data_exists:
    "This Dealer Contact Number is already registered with us. Our Team will get back to you shortly."
};
