import React, { useState } from "react";
import { CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  media: {
    width: "50px",
    height: "50px",
    marginTop: "30px",
    objectFit: "contain"
  },
  sectionTitle: {
    width: "256px",
    height: "26px",
    marginTop: "13px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 1.44,
    color: "#241d41"
  }
});

const CustomSurplusTextPart = imageProps => {
  const classes = useStyles();

  return (
    <div>
      <CardMedia className={classes.media} image={imageProps.imagePath} />
      <Typography className={classes.sectionTitle}>
        {imageProps.heading}
      </Typography>
      <label
        style={{
          width: "200px",
          height: "32px",
          marginRight: "97px",
          opacity: 0.8,
          fontFamily: "Montserrat",
          fontSize: 13,
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          color: "#241d41"
        }}
      >
        {imageProps.subHeading}
      </label>
    </div>
  );
};

export default CustomSurplusTextPart;
