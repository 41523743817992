import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  CardMedia,
  Typography,
  TextField,
  Button,
  Grid,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import tph from "../../src/assets/images/tph.png";
import dstock from "../../src/assets/images/dstock_icon.jpeg";
import loginLine from "../../src/assets/images/loginline.svg";
import otpLine from "../../src/assets/images/otpLine.svg";
import telephone from "../../src/assets/images/telephone.svg";
import envelope from "../../src/assets/images/envelope.svg";
import emailIcon from "../../src/assets/images/email_icon.svg";
import { Route, Redirect } from "react-router-dom";
import Validator from "../util/validator";
import AlertDailog from "../CommonComponents/AlertDialog";
import AllString from "../ConstFiles/StringConstant";
import callServer from "../Apiservice/ApiExecutor";
import LoadingDialog from "../CommonComponents/LoadingDialog";
import { validateAllInputFields } from "../util/validator";
import ApiEndPoint from "../Apiservice/ApiName";
import { takeResponseParsingDecision } from "../Apiservice/ApiResponseHttpParser";
import RegisterLoginCard from "../CustomClasses/RegisterLoginCard";
import App from "../App";
import "../../src/styles/loginstyle.css";
import { logger } from "../util/CommonFunction";

const useStyles = makeStyles({
  root_grid: {
    flexGrow: 1,
    marginTop: "40px"
  },
  logincard: {
    width: "590px",
    height: "610px",
    borderRadius: "20px",
    textAlign: "center",
    // marginLeft: "450px",
    backgroundColor: "#ffffff"
  },
  enq_success_card: {
    width: "390px",
    height: "410px",
    borderRadius: "20px",
    textAlign: "center",
    // marginLeft: "450px",
    backgroundColor: "#ffffff"
  },
  dstockmedia: {
    width: "250px",
    height: "54px",
    marginTop: "94px",
    //  marginLeft: "173px",
    // marginRight: "173px",
    //backgroundSize: "auto",
    display: "inline-flex"
  },
  media: {
    width: "250px",
    height: "54px",
    marginTop: "94px",
    //  marginLeft: "173px",
    // marginRight: "173px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  welcome_back: {
    //width: "170px",
    height: "27px",
    // marginLeft: "220px",
    // marginRight: "204px",
    marginTop: "30px",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  login_account: {
    // width: "341px",
    height: "20px",
    // marginLeft: "146px",
    // marginRight: "126px",
    marginTop: "6px",
    fontFamily: "Montserrat",
    fontSize: "30px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  otpnumber_typography: {
    //width: "291px",
    height: "24px",
    //  marginLeft: "180px",
    // marginRight: "149px",
    marginTop: "25px",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    color: "#241d41"
  },
  line: {
    width: "92px",
    height: "6px",
    //marginLeft: "260px",
    //marginRight: "253px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  textField: {
    width: "340px",
    marginTop: "31px",
    borderRadius: "8px",
    boxShadow: "inset 0 0 1px 0 #20dada",
    border: " 0.1px #2fbea8",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    }
  },
  otpTextField: {
    width: "340px",
    marginTop: "16px",
    borderRadius: "8px",
    boxShadow: "inset 0 0 1px 0 #20dada",
    border: " 0.1px #2fbea8",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    }
  },

  button: {
    marginTop: 20,
    width: "340px",
    height: "50px",
    // marginLeft: "123px",
    // marginRight: "127px",
    fontWeight: 600,
    borderRadius: "8px",
    color: "#FFFFFF",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    fontFamily: "Montserrat",
    fontSize: "20px",
    textTransform: "none"
  },
  input: {
    height: 43
  },
  exclusive_service: {
    height: "40px",
    fontSize: "30px",
    paddingLeft: "10px",
    paddingRight: "20px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#241d41",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#FFFFFF"
  },
  delaer: {
    height: "61px",
    fontSize: "50px",
    marginTop: "12px",
    paddingRight: "20px",
    color: "#ffffff",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    lineHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal"
  },
  contact_us: {
    fontSize: "20px",
    // marginTop: "300px",
    paddingRight: "20px",
    fontWeight: 600,
    color: "#ffffff",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    lineHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal"
  },
  contact_us_detail: {
    fontSize: "16px",
    marginLeft: "10px",
    marginBottom: "20px",
    fontWeight: 500,
    color: "#ffffff",
    fontFamily: "Quicksand",
    lineHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal"
  },
  button_txt: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  href_style: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4d54ac"
  },
  paper: {
    // padding: theme.spacing(32),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    backgroundColor: "yellow"
  },

  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)"
    // gridGap: theme.spacing(3)
  },
  telephone_style: {
    width: "14px",
    height: "12px",
    opacity: 0.77,
    backgroundSize: "auto",
    display: "inline-flex"
  },
  email_icon_style: {
    width: "60px",
    height: "60px",
    marginTop: "63px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  thanku_for_register: {
    marginTop: "30px",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  our_representative_style: {
    marginTop: "26px",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#504a67"
  }
});

export default function Login(props) {
  const [values, setValues] = useState({
    userId: "",
    password: "",
    otpCode: ""
  });
  const [otpResendTimerValue, setOtpCounter] = useState(30);
  const [isOtpTimerStart, setStartOtpTimer] = useState(false);
  const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isClickedOnRegister, setIsClickedOnRegister] = useState(false);
  const [isEnqRegistedSuccess, setIsEnqRegistedSuccess] = useState(false);
  const [loginResObj, setLoginResObj] = useState({});
  const [alertMsg, setAlertMsg] = useState("");

  const classes = useStyles();

  // Common function for open alert dialog from all function within a class..
  const alertDialogOpen = msg => {
    setIsShowAlertDialog(true);
    setAlertMsg(msg);
  };

  const makeServerCall = () => {
    // Validate UserId/Password fields with blank
    var msgMy = validateAllInputFields(values.userId, values.password);
    if (msgMy.length > 0) {
      alertDialogOpen(msgMy);
      return;
    }

    if (isOtpSent && values.otpCode.length === 0) {
      alertDialogOpen(AllString.enterOtp);
      return;
    }

    var payload;
    if (isOtpSent) {
      payload = {
        requestType: "VERIFY_OTP",
        mobileNumber: loginResObj.data.mobileNumber,
        otpCode: values.otpCode
      };
    } else {
      payload = {
        requestType: "LOGIN",
        loginId: values.userId,
        password: values.password
      };
    }
    callServer(
      ApiEndPoint.dealerRequest,
      JSON.stringify(payload),
      "POST",
      apiResponse => {
        let responseData = takeResponseParsingDecision("POST", apiResponse);
        logger("ApiRes -- ", JSON.stringify(responseData));
        setIsShowLoading(false);
        if (responseData === undefined || responseData === null) {
          alertDialogOpen(AllString.someWentWrong);
          return;
        }

        setIsShowLoading(false);
        if (responseData.statusCode !== 200) {
          alertDialogOpen(responseData.message);
          return;
        } else {
          if (payload.requestType === "LOGIN") {
            setLoginResObj(responseData);
            props.logInUser(responseData.data);
            setIsOtpSent(true);
            setStartOtpTimer(true);
          } else if (payload.requestType === "VERIFY_OTP") {
            setRedirectToDashboard(true);
          }
        }
      }
    );

    setIsShowLoading(isShowLoading => !isShowLoading);
    logger("Logging res-->>", JSON.stringify(loginResObj));
    //setLoginShowFunction(isLoginScreenShow => !isLoginScreenShow);
  };
  // Execute below code when clicked on send otp button
  const sendOtpClick = () => {
    // props.logInUser(myRes.data);
    // setRedirectToDashboard(true);
    makeServerCall();
  };

  // Execute below code when clicked on Submit button
  const submitClick = () => {
    makeServerCall();
  };

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      makeServerCall();
    }
  };
  // Otp countDown timer
  if (isOtpTimerStart && otpResendTimerValue > 0) {
    setTimeout(() => setOtpCounter(otpResendTimerValue - 1), 1000);
  }

  // Resend OTP Clicked function
  const resendOTPClick = () => {
    setStartOtpTimer(true);
    setOtpCounter(30);
  };

  {
    if (redirectToDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/surplus-stock",
            state: { loginResponseObj: loginResObj.data }
          }}
          push
        />
      );
      //return <App dataObject={loginResObj} />;
    }
  }
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleHrefClick = () => {
    setIsClickedOnRegister(true);
  };

  const openLoginPage = isAlreadyMember => {
    setIsClickedOnRegister(false);
  };

  const showEnquirySuccessCard = () => {
    setIsEnqRegistedSuccess(true);
  };

  const backToLoginCardCliced = () => {
    setIsEnqRegistedSuccess(false);
    setIsClickedOnRegister(false);
  };

  return (
    <div
      className="parent-div"
      style={{
        height: "100%",
        width: "100%",
        backgroundImage: isEnqRegistedSuccess
          ? "url(" +
            `${require("../../src/assets/images/enq_success_background.png")}` +
            ")"
          : "url(" +
            `${require("../../src/assets/images/login_background.jpg")}` +
            ")"
      }}
    >
      {isEnqRegistedSuccess ? (
        <div>
          <div
            className={classes.logincard}
            style={{ marginTop: 50, marginBottom: 50 }}
          >
            <CardMedia
              className={classes.dstockmedia}
              image={dstock}
              title="The Purchase House"
            />
            <div>
              <Divider variant="middle" style={{ marginTop: 30 }} />

              <CardMedia
                className={classes.email_icon_style}
                image={emailIcon}
                title="The Purchase House"
              />

              <Typography className={classes.thanku_for_register}>
                Thank you for registering with us
              </Typography>
              <Typography className={classes.our_representative_style}>
                Our representative will get back to you shortly with further
                process.
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                onClick={backToLoginCardCliced}
                style={{ marginTop: "24px" }}
              >
                <span className={classes.button_txt}>Back to login</span>
              </Button>
            </div>
          </div>

          {/*<CardMedia
            className={classes.dstockmedia}
            image={dstock}
            title="The Purchase House"
          />*/}
        </div>
      ) : (
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={12}
            spacing={3}
            spacing={0}
            style={{ marginTop: "5%", marginBottom: "50px" }}
          >
            <div className={classes.container}>
              <div style={{ gridColumnEnd: "span 6" }}>
                <div style={{ marginLeft: "100px" }}>
                  <label className={classes.exclusive_service}>
                    DStock- An Exclusive e-Portal by TPH
                  </label>
                  <div style={{ marginTop: 20 }}></div>
                  <label className={classes.exclusive_service}>
                    To Manage & Liquidate
                  </label>
                  <div style={{ marginTop: 20 }}></div>
                  <label className={classes.exclusive_service}>
                    Surplus/Non-Moving Stock of
                  </label>
                  <Typography className={classes.delaer}>
                    Industrial Dealers & Distributors
                  </Typography>
                  <div style={{ marginTop: "300px" }}>
                    <Typography className={classes.contact_us}>
                      Contact us on:
                    </Typography>
                    <div style={{ marginTop: "30px" }}>
                      <CardMedia
                        className={classes.telephone_style}
                        image={telephone}
                      />
                      <h className={classes.contact_us_detail}>8308354446</h>
                      <h
                        className={classes.contact_us_detail}
                        style={{ marginLeft: "30px" }}
                      >
                        |
                      </h>
                      <CardMedia
                        className={classes.telephone_style}
                        image={envelope}
                        style={{ marginLeft: "30px" }}
                      />
                      <h className={classes.contact_us_detail}>
                        dstock@thepurchasehouse.com
                      </h>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ gridColumnEnd: "span 4" }}>
                <Card className={classes.logincard}>
                  {/* <CardMedia
                className={classes.media}
                image={tph}
                title="The Purchase House"
              />*/}
                  {isClickedOnRegister ? (
                    <div>
                      <RegisterLoginCard
                        onHrefClick={openLoginPage}
                        onRegisteredSuccessEnq={showEnquirySuccessCard}
                      />
                    </div>
                  ) : (
                    <div>
                      <CardMedia
                        className={classes.dstockmedia}
                        image={dstock}
                        title="The Purchase House"
                      />
                      <CardContent>
                        <Typography className={classes.welcome_back}>
                          Welcome
                        </Typography>

                        <Typography className={classes.login_account}>
                          Login to your account
                        </Typography>
                      </CardContent>
                      <CardMedia
                        className={classes.line}
                        image={!isOtpSent ? loginLine : otpLine}
                        title="The Purchase House"
                      />
                      <div
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                      >
                        {!isOtpSent ? (
                          <div>
                            <TextField
                              fullWidth
                              className={classes.textField}
                              id="outlined-basic"
                              placeholder="User ID"
                              variant="outlined"
                              color="secondary"
                              value={values.userId}
                              onChange={handleChange("userId")}
                              onKeyPress={handleKeypress}
                              autoFocus
                              InputProps={{
                                className: classes.input
                              }}
                            />
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              placeholder="Password"
                              variant="outlined"
                              type="password"
                              value={values.password}
                              onChange={handleChange("password")}
                              onKeyPress={handleKeypress}
                              className={classes.textField}
                              InputProps={{
                                className: classes.input
                              }}
                            />
                            <Button
                              className={classes.button}
                              variant="contained"
                              onClick={sendOtpClick}
                              //onClick={() => setCount(count + 1)}
                            >
                              <span className={classes.button_txt}>
                                Get OTP
                              </span>
                            </Button>
                            <div style={{ marginTop: "20px" }}>
                              <a
                                className={classes.href_style}
                                href={"#"}
                                onClick={handleHrefClick}
                              >
                                Register with us
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Typography
                              className={classes.otpnumber_typography}
                            >
                              OTP successfully sent to +91{""}
                              {loginResObj.data.mobileNumber}
                            </Typography>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              placeholder="Enter OTP"
                              variant="outlined"
                              className={classes.otpTextField}
                              value={values.otpCode}
                              onChange={handleChange("otpCode")}
                              onKeyPress={handleKeypress}
                              majid
                              InputProps={{
                                className: classes.input
                              }}
                            />
                            <Button
                              className={classes.button}
                              variant="contained"
                              onClick={submitClick}
                              //onClick={() => setCount(count + 1)}
                            >
                              <span className={classes.button_txt}>Submit</span>
                            </Button>
                            {otpResendTimerValue === 0 ? (
                              <Button
                                className={classes.button}
                                variant="contained"
                                onClick={resendOTPClick}
                              >
                                <span className={classes.button_txt}>
                                  Resend OTP
                                </span>
                              </Button>
                            ) : (
                              <div></div>
                            )}

                            {otpResendTimerValue !== 0 ? (
                              <p className="login_timer">
                                Resend OTP in {otpResendTimerValue} Sec
                              </p>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </Grid>
        </div>
      )}

      <AlertDailog
        isShowAlertDialog={isShowAlertDialog}
        onOkClickButton={() => setIsShowAlertDialog(false)}
        alertMsg={alertMsg}
      />
      <LoadingDialog showLoading={isShowLoading} />
    </div>
  );
}
