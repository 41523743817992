import { logger } from "../util/CommonFunction";
import AlertDialog from "../CommonComponents/AlertDialog";
var takeResponseParsingDecision = (methodType, apiResponse) => {
  if (apiResponse != undefined && apiResponse != null) {
    if (apiResponse.status != undefined) {
      if (apiResponse.status === 200 && methodType.toUpperCase() === "POST") {
        return apiResponse.data;
      } else if (
        apiResponse.status === 200 &&
        methodType.toUpperCase() === "GET"
      ) {
        return apiResponse.data;
      } else {
        alert("Something went wrong");
      }
    }
  }
};

var decisionTaker = apiResponse => {
  logger("Parser::", JSON.stringify(apiResponse.data));
  if (apiResponse.data.isSuccess === true) {
    return apiResponse.data;
  } else if (apiResponse.data.isSuccess === false) {
    //alert('',apiResponse.data.message);
    return apiResponse.data;
  } else {
    AlertDialog("Something went wrong");
  }
};

export { takeResponseParsingDecision, decisionTaker };
