import React, { useState } from "react";
import { CardMedia, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  stepto_upload_surplus: {
    width: "282px",
    height: "52px",
    paddingTop: "24px",
    marginLeft: "25px",
    marginRight: "27px",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 1.44,
    color: "#241d41"
  },
  stepto_upload_subtext: {
    width: "282px",
    height: "42px",
    opacity: 0.8,
    fontSize: "14px",
    fontWeight: 500,
    color: "#241d41"
  }
});

function BoxBottomSmalText(props) {
  const greeting = props.msg;

  return (
    <label
      style={{
        width: "307px",
        height: "32px",
        marginTop: "15px",
        display: "inline-flex",
        opacity: 0.8,
        fontFamily: "Montserrat",
        fontSize: "13px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        lineHeight: 1.44,
        color: "#241d41"
      }}
    >
      {greeting}
    </label>
  );
}

export default BoxBottomSmalText;
