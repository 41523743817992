import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class Logout extends React.Component {
  state = {
    open: true,
  };

  handleSession = () => {
    this.setState({ open: false });
    this.props.closeSession();
  };

  handleClose = () => {
    this.setState({ open: false  });
    this.props.closeDialog();
  };
  componentDidMount()
  {
      console.log("Logout Props",this.props)
  }

  render() {
    return (
      <div>
      
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.customMessage!==undefined ? this.props.customMessage : " Do you want to Logout ?"}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
             Do you Want to Logout ?
            </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSession} color="primary" autoFocus>
              {this.props.customMessage!==undefined ? "Proceed" : "Logout"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Logout;