import React, { useState } from "react";
import { CardMedia, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  stepto_upload_surplus: {
    width: "282px",
    height: "52px",
    paddingTop: "24px",
    marginLeft: "25px",
    marginRight: "27px",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 1.44,
    color: "#241d41"
  },
  stepto_upload_subtext: {
    width: "282px",
    height: "42px",
    opacity: 0.8,
    fontSize: "14px",
    fontWeight: 500,
    color: "#241d41"
  }
});

function PageFooterQueryText(props) {
  const greeting = props.msg;
  var link = (
    <a href={"mailto:dstock@thepurchasehouse.com"}>
      dstock@thepurchasehouse.com
    </a>
  );
  return (
    <label
      style={{
        width: "707px",
        height: "27px",
        marginTop: "40px",
        display: "flex",
        opacity: 0.5,
        fontFamily: "Poppins-Medium",
        fontSize: "15px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        lineHeight: 1,
        color: "#241d41",
        marginLeft: 20
      }}
    >
      <h>For any query email us at </h>
      <h style={{ color: "#FEFFFE" }}>h</h>
      {link}
      <h style={{ color: "#FEFFFE" }}>h</h>
      or Just Call us on 8308354446
      {/*greeting*/}
    </label>
  );
}

export default PageFooterQueryText;
