import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  MenuList,
  MenuItem,
  Hidden,
  Typography,
  Divider
} from "@material-ui/core";
import SurplusStock from "../pages/SurplusStock";
import BillOfMaterial from "../pages/BillOfMaterial";
import { Route, Redirect, Link } from "react-router-dom";

const styles = theme => ({
  drawer: {
    background: "#ffffff",
    width: 190,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "10rem"
    }
  },
  card_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minHeight: 64
  },
  logo: {
    width: "160px",
    height: "32px",
    marginRight: "36px",
    marginTop: "19px",
    marginLeft: "25px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  dstock_logo: {
    width: "160px",
    height: "52px",
    marginRight: "36px",
    marginTop: "19px",
    marginLeft: "25px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  list_item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    marginTop: "0",
    backgroundColor: "#ffffff",
    "& span": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      color: "#000000"
    }
  },
  selected_item: {
    backgroundColor: "blue"
  },
  title_txt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    color: "#ffffff"
  },
  // Dealer
  surplus_stock_style: {
    width: "188px",
    height: "48px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#aa0c0c"
  }
});

var surplusClickCounter = 0;
var bomClickCounter = 0;
var downloadHistoryCounter = 0;
var historyCounter = 0;

class LeftAnchoredDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surplusSelected: false,
      billMaterialSelected: false,
      downloadHistorySelected: false,
      historySelected: false,
      howItWork: false
    };
  }

  showModule = moduleName => {
    this.menuItemClicked(moduleName);
    switch (moduleName) {
      case "Surplus Stock":
        this.setState({
          surplusSelected: true
        });
        surplusClickCounter = surplusClickCounter + 1;
        bomClickCounter = 0;
        downloadHistoryCounter = 0;
        historyCounter = 0;
        break;
      case "Bom":
        this.setState({
          billMaterialSelected: true
        });
        bomClickCounter = bomClickCounter + 1;
        surplusClickCounter = 0;
        downloadHistoryCounter = 0;
        historyCounter = 0;
        break;
      case "Your Results":
        this.setState({
          downloadHistorySelected: true
        });
        downloadHistoryCounter = downloadHistoryCounter + 1;
        surplusClickCounter = 0;
        bomClickCounter = 0;
        historyCounter = 0;
        break;
      case "History":
          this.setState({
            historySelected: true
          });
          historyCounter = historyCounter + 1;
          downloadHistoryCounter = 0;
          surplusClickCounter = 0;
          bomClickCounter = 0;
          break;

      default: {
        console.log("Default executed : ");
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      surplusSelected,
      billMaterialSelected,
      downloadHistorySelected,
      historySelected,
      isLogout
    } = this.state;

    if (
      surplusClickCounter === 0 &&
      bomClickCounter === 0 &&
      downloadHistoryCounter === 0 && 
      historyCounter   === 0
    ) {
      if (this.props.selectedOption === "Bom") {
        bomClickCounter = 2;
      }

      if (this.props.selectedOption === "Surplus Stock") {
        surplusClickCounter = 2;
      }
      if (this.props.selectedOption === "Your Results") {
        downloadHistoryCounter = 2;
      }
      if (this.props.selectedOption === "Hisotry") {
        historyCounter = 2;
      }
    }

    if (surplusClickCounter == 1 && surplusSelected) {
      return (
        <Redirect
          to={{
            pathname: "/surplus-stock",
            state: { loginData: this.props.loginData }
          }}
          push
        />
      );
    }
    if (bomClickCounter == 1 && billMaterialSelected) {
      return (
        <Redirect
          to={{
            pathname: "/bill-of-material",
            state: { loginData: this.props.loginData }
          }}
          push
        />
      );
    }
    if (downloadHistoryCounter == 1 && downloadHistorySelected) {
      return (
        <Redirect
          to={{
            pathname: "/your-results",
            state: { loginData: this.props.loginData }
          }}
          push
        />
      );
    }
    if (historyCounter == 1 && historySelected) {
      return (
        <Redirect
          to={{
            pathname: "/history",
            state: { loginData: this.props.loginData }
          }}
          push
        />
      );
    }
    console.log("surplusClickCounter---" + surplusClickCounter);
    console.log("bomClickCounter---" + bomClickCounter);
    return (
      <div>
        <Drawer
          anchor="left"
          variant="permanent"
          classes={{ paper: classes.drawer }}
        >
          <Hidden>
            <div className={classes.toolbar} />
          </Hidden>
          {/*<div className={classes.card_header}>
            <img
              className={classes.logo}
              src={require("../../src/assets/images/tph_logo.png")}
              alt="tph"
            />
          </div>*/}
          <div className={classes.card_header}>
            <img
              className={classes.dstock_logo}
              src={require("../../src/assets/images/dstock_icon.jpeg")}
              alt="tph"
            />
          </div>

          <Divider
            style={{
              width: "100%",
              // marginBottom: "12px",
              marginTop: "50px"
            }}
          />

          <List>
            <ListItem
              button
              key="Surplus Stock"
              onClick={() => this.showModule("Surplus Stock")}
              selected={"Surplus Stock" === this.props.selectedOption}
              classes={{ selected: "my-class" }}
              style={{
                height: "112px",
                maxHeight: 132,
                backgroundColor:
                  surplusClickCounter >= 1 ? "#2fbea8" : "#FFFFFF",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal"
              }}
            >
              {"Surplus Stock" === this.props.selectedOption ? (
                <span style={{ color: "#ffffff" }}>
                  Surplus Stock / Non-moving Inventory
                </span>
              ) : (
                <span>Surplus Stock / Non-moving Inventory</span>
              )}
            </ListItem>

            <Divider style={{ width: "100%" }} />

            <ListItem
              button
              key="Bom"
              onClick={() => this.showModule("Bom")}
              selected={"Bom" === this.props.selectedOption}
              classes={{ selected: "my-class" }}
              style={{
                height: "112px",
                maxHeight: 132,
                //backgroundColor: billMaterialSelected ? "#2fbea8" : "#FFFFFF"
                backgroundColor: bomClickCounter >= 1 ? "#2fbea8" : "#FFFFFF",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal"
              }}
            >
              {"Bom" === this.props.selectedOption ? (
                <span style={{ color: "#ffffff" }}>
                  Bill of Material (BOM) / Indent
                </span>
              ) : (
                <span>Bill of Material (BOM) / Indent</span>
              )}
            </ListItem>

            <Divider style={{ width: "100%" }} />

            <ListItem
              button
              key="Your Results"
              onClick={() => this.showModule("Your Results")}
              selected={"Your Results" === this.props.selectedOption}
              classes={{ selected: "my-class" }}
              style={{
                height: "112px",
                maxHeight: 132,
                //backgroundColor: billMaterialSelected ? "#2fbea8" : "#FFFFFF"
                backgroundColor:
                  downloadHistoryCounter >= 1 ? "#2fbea8" : "#FFFFFF",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal"
              }}
            >
              {"Your Results" === this.props.selectedOption ? (
                <span style={{ color: "#ffffff" }}>Your Results</span>
              ) : (
                <span>Your Results</span>
              )}
            </ListItem>

            <Divider style={{ width: "100%" }} />

            <ListItem
              button
              key="History"
              onClick={() => this.showModule("History")}
              selected={"History" === this.props.selectedOption}
              classes={{ selected: "my-class" }}
              style={{
                height: "112px",
                maxHeight: 132,
                //backgroundColor: billMaterialSelected ? "#2fbea8" : "#FFFFFF"
                backgroundColor:
                  historyCounter >= 1 ? "#2fbea8" : "#FFFFFF",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal"
              }}
            >
              {"History" === this.props.selectedOption ? (
                <span style={{ color: "#ffffff" }}>History</span>
              ) : (
                <span>History</span>
              )}
            </ListItem>

            <Divider style={{ width: "100%" }} />
          </List>
        </Drawer>
      </div>
    );
  }

  menuItemClicked = menyType => {
    switch (menyType) {
      case "surplus":
        this.setState({ surplusSelected: true });
        this.setState({ billMaterialSelected: false });
        this.setState({ downloadHistorySelected: false });
        break;
      case "bom":
        this.setState({ surplusSelected: false });
        this.setState({ billMaterialSelected: true });
        this.setState({ downloadHistorySelected: false });
        break;
      case "Your Results":
        this.setState({ surplusSelected: false });
        this.setState({ billMaterialSelected: false });
        this.setState({ downloadHistorySelected: true });
        break;
    }
  };
}

LeftAnchoredDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  openDrawer: PropTypes.bool.isRequired
};
export default withStyles(styles)(LeftAnchoredDrawer);
