export const styles = theme => ({
  myCustomClass: {
    fontFamily: "Arial"
  },
  parentCardStyle: {
    width: "85vw",
    height: "90vh",
    display: "block",
    marginRight: "65%",
    transitionDuration: "0.39",
    borderRadius: 1,
    // backgroundColor: "#FFFFFF"
    backgroundColor: "rgb(243,246,254)"
    //color: theme.palette.primary.contrastText
  },
  cardHeaderStyle: {
    width: "593px",
    height: "27px",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 600,
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  parentGrid: {
    marginTop: 12,
    backgroundColor: "rgb(243,246,254)"
    //  marginLeft: 10,
    //  marginRight: 20
  },
  boxStyle: {
    paddingLeft: "29px",
    paddingRight: "29px"
  },
  custom_label: {
    width: "306px",
    height: "45px",
    color: "#fff",
    borderRadius: "8px",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    padding: "6px 12px",
    height: "51px",
    cursor: "pointer",
    textAlign: "center",
    lineHeight: "40px",
    color: "white",
    fontFamily: "poppins",
    fontSize: "17px",
    textTransform: "none",
    marginTop: "30px"
  },
  button: {
    width: "306px",
    height: "45px",
    color: "#fff",
    display: "flex",
    fontFamily: "Montserrat",
    borderRadius: "8px",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    fontSize: "17px",
    textTransform: "none",
    marginTop: "30px"
  },
  input_file: {
    width: "0.1px",
    height: "0.1px",
    opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zindex: "-1"
  },
  custom_upload_label_button: {
    width: "306px",
    height: "45px",
    color: "#fff",
    // display: "flex",
    display: "inline-block",
    borderRadius: "8px",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    fontSize: "17px",
    textTransform: "none",
    marginTop: "30px",
    textAlign: "center",
    lineHeight: "40px"
  }
});
