import React, { useState } from "react";
import { CardMedia, Typography, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import downloadImage from "../assets/images/surplus_download.svg";

const useStyles = makeStyles({
  selectbrandhstyle: {
    width: "307px",
    height: "50px",
    marginTop: "13px",
    fontFamily: "Montserrat",
    borderRadius: "8px",
    border: "0.5px #979797",
    backgroundColor: "#ffffff"
  },
  menuItem: {
    opacity: 0.8,
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    fontFamily: "Montserrat",
    color: "#241d41"
  }
});

const SelectDropDown = props => {
  const classes = useStyles();
  const [selectedBrand, setselectedBrand] = useState("");

  const handleChangeSelector = event => {
    setselectedBrand(event.target.value);
    props.returnValue(event.target.value, props.selectorType);
  };

  return (
    <Select
      variant="outlined"
      // id="loanCalculationStrategy"
      displayEmpty
      value={selectedBrand}
      onChange={handleChangeSelector}
      className={classes.selectbrandhstyle}
    >
      <MenuItem value="" disabled className={classes.menuItem}>
        Select Brand
      </MenuItem>
      {props.menuItem.map((data, index) => (
        <MenuItem
          key={index}
          value={data.brandName}
          className={classes.menuItem}
        >
          {data.brandName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectDropDown;
