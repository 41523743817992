import React, { useState, useEffect } from "react";

import {
  Avatar,
  Grid,
  Divider,
  makeStyles,
  Backdrop,
  CircularProgress,
  Snackbar,
  Button,
} from "@material-ui/core";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import { X as XIcon } from "react-feather";

const type = 'xlsx'

const useStyles = makeStyles((theme) => ({
  root1: {
    padding: "1rem",
    overflowX: "hidden",
    width: "100vw",
  },
  inner1: {
    display: "flex",
    justifyContent: "flex-start",
    overflowX: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ExcellViewer(props) {
  const classes = useStyles();

  const onError=(e)=> {
    //logger.logError(e, 'error in file-viewer');
  }
  
  return (
    <div>
      <Grid container justify="flex-end" className={classes.root1}>
        <Grid item xl={2} md={4} style={{ textAlign: "end" }}>
          <Button
            onClick={() => props.setopen(false)}
            style={{ cursor: "pointer", color: "red" }}
          >
            <XIcon /> Close
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <div style={{ height: '150%' }}>
      <FileViewer
          fileType={type}
          filePath={props.url}
          errorComponent={CustomErrorComponent}
          onError={onError}
        />
</div>
       
    </div>
  );
}

export default ExcellViewer;
