import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Hidden,
  MenuList
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import Drawer from "./drawer";
import Logout from "../pages/Logout";

import { Redirect, Link } from "react-router-dom";
const drawerWidth = 190;
const ITEM_HEIGHT = 20;
const styles = theme => ({
  root: {
    flexGrow: 1,
    color: "#2ec3d2",
    backgroundColor: "#ffffff",
    textTransform: "uppercase",
    textAlign: "left"
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  toolbar: {
    backgroundColor: "#ffffff",
    minHeight: 64,
    display: "flex",
    justifyContent: "space-between"
    //
  },
  grow: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    marginTop: 11,
    justifyContent: "space-around"
  },
  logoImg: {
    width: 175,
    marginLeft: 13,
    marginRight: 177,
    [theme.breakpoints.down("sm")]: {
      width: 125,
      marginLeft: 0,
      marginRight: 126
    },
    [theme.breakpoints.down("xs")]: {
      width: 125,
      marginLeft: 0,
      marginRight: 90
    }
  },
  headerTxt: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 2,
    color: "#607D8B",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "#608D9D",
      textDecoration: "underline",
      transition: "color 0.8s"
    }
  },
  buttonContainer: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[400],
    "&:hover": {
      backgroundColor: blue[500]
    }
  },
  buttonTxt: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: 2,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px"
    }
  },
  titleTxt: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#241d41"
  },
  userName: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    color: "#1b0c39"
  },
  arrowImg: {
    width: "23px",
    height: "23px"
  }
});
const options = ["Logout"];

class Header extends React.Component {
  state = {
    mobileOpen: false,
    drawerOpen: false,
    surplusstock: false,
    billofmaterial: false,
    dashboard: false,
    changePwd: false,
    anchorEl: null,
    isLogout: false
  };

  toggleDrawer = open => () => {
    this.setState({
      drawerOpen: open
    });
  };

  redirectTo = name => () => {
    console.log("Route name : ", name);
    this.setState({
      [name]: true
    });
  };

  handleClick = event => {
    console.log("Current Target : ", event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = option => {
    console.log("Option selected : ", option);
    switch (option) {
      case "Change Password":
        this.setState({
          changePwd: true
        });
        break;
      case "Logout":
        this.LogoutUser();
        break;
      default: {
        console.log("Default executed : ");
      }
    }
  };
  LogoutUser = () => {
    console.log("LogoutUser");
    this.setState({ isLogout: true });
  };
  closeDialog = () => {
    console.log("closeDialog");
    this.setState({ isLogout: false });
  };
  closeSession = () => {
    console.log("closeSession");
    this.props.logOutUser();
  };

  render() {
    const { classes, children, loginData } = this.props;
    const {
      mobileOpen,
      drawerOpen,
      surplusstock,
      billofmaterial,
      dashboard,
      onboardedEmployees,
      changePwd,
      isLogout,
      anchorEl
    } = this.state;

    console.log("loginData===>>" + JSON.stringify(loginData));

    const open = Boolean(anchorEl);
    if (changePwd) {
      return (
        <Redirect
          to={{
            pathname: "/change-pwd"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }
    if (surplusstock) {
      return (
        <Redirect
          to={{
            pathname: "/surplus-stock"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }

    if (dashboard) {
      return (
        <Redirect
          to={{
            pathname: "/surplus-stock"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }
    if (billofmaterial) {
      return (
        <Redirect
          to={{
            pathname: "/bill-of-material"
            // state: { employerObj: this.props.employerObj }
          }}
          push
        />
      );
    }

    const drawer = (
      <div>
        <Hidden>
          <div className={classes.toolbar} />
        </Hidden>
        <MenuList>
          <MenuItem component={Link} to="/surplus-stock">
            Surplus Stock / Non-moving Inventory
          </MenuItem>
          <MenuItem component={Link} to="/bill-of-material">
            Bill of Material ( BOM) / Indent
          </MenuItem>
        </MenuList>
      </div>
    );
    return (
      <div>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <span className={classes.titleTxt} variant="h6"></span>
            <div>
              <span className={classes.userName}>
                {/*this.props.employerObj.employerName*/}

                {loginData.firstName}
              </span>
              <IconButton
                aria-label="More"
                aria-owns={open ? "long-menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <img
                  className={classes.arrowImg}
                  src={require("../../src/assets/images/down-arrow.png")}
                  //alt="down-arrow"
                />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 200
                  }
                }}
              >
                {options.map(option => (
                  <MenuItem
                    key={option}
                    onClick={() => this.handleMenu(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          openDrawer={drawerOpen}
          toggleDrawer={this.toggleDrawer}
          logOutUser={this.props.logOutUser}
          loginData={this.props.loginData}
          selectedOption={this.props.selectedOption}
        />

        {/* <div style={{ marginLeft: drawerWidth }}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div
              style={{
                //marginLeft: DRAWER_WIDTH,
                // backgroundColor: "rgb(243,246,254)",
                backgroundColor: "#edf1fd",
                // height: "100vh",
                // width: "1000vh",
                borderRadius: "5px",
                transition: "0.3s",
                display: "flex",
                borderColor: "#eef0f2",
                // border: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
                border: "10px solid rgb(237,241,253)"
              }}
            >
              {children}
            </div>
          </main>
        </div>
  */}
        {/*isLogout ? window.location.reload() : null*/}
        {/*isLogout ? window.location.reload() : null*/}
        {isLogout ? (
          <div>
            <Logout
              isOpen={isLogout}
              closeDialog={this.closeDialog}
              closeSession={this.closeSession}
              customMessage={undefined}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
Header.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Header);
