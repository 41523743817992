import axios from "axios";
import apiName from "../Apiservice/ApiName";
import { logger } from "../util/CommonFunction";

var headerConfig = {
  headers: { "Content-Type": "application/json; charset=utf8" }
};
var callApi = (endPoint, payload, methodType, apiCallBack) => {
  try {
    let baseUrl = apiName.baseUrl + endPoint;

    logger("Url --", baseUrl);
    logger("Payload -- ", payload);

    if (methodType.toUpperCase() === "POST") {
      axios
        .post(baseUrl, payload, headerConfig)
        .then(response => {
          logger("POST Response ", JSON.stringify(response));

          apiCallBack(response);
        })
        .catch(error => {
          logger("POST Error in API Connection : ", error);

          apiCallBack(error);
        });
    } else if (methodType.toUpperCase() === "GET") {
      axios
        .get(baseUrl)
        .then(response => {
          logger("GET Response-- ", JSON.stringify(response));

          apiCallBack(response);
        })
        .catch(error => {
          logger("GET Error in API Connection : ", error);

          apiCallBack(error);
        });
    }
  } catch (methodError) {
    logger("Catch -- ", methodError);
    apiCallBack(methodError);
  }
};

export default callApi;
