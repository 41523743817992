import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

class LoadingDialog extends React.Component {
  render() {
    return (
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          }
        }}
        open={this.props.showLoading}
        disableBackdropClick={true}
        onClose={this.handleRejection}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 216,
            height: 93,
            background: "#ffffff",
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5
          }}
        >
          <h1
            style={{
              fontFamily: "Quicksand",
              fontSize: "20px",
              fontWeight: "bold",
              lineHeight: "1.14",
              letterSpacing: "1.3px",
              textAlign: "left",
              color: "#000000",
              marginBottom: "20px"
            }}
          >
            Please wait
          </h1>

          <img
            src={require("../assets/images/animated-loading.gif")}
            alt="loading"
          />
        </div>
        <DialogContent />
      </Dialog>
    );
  }
}
export default LoadingDialog;
