var isEnableProd = true; // change flag value as per enviornment

export default {
  logShow: isEnableProd ? false : true,

  // All Base Url
  baseUrl: isEnableProd
    ? " https://jkspiyfumd.execute-api.ap-south-1.amazonaws.com/prod"
    : " https://jkspiyfumd.execute-api.ap-south-1.amazonaws.com/qa",

  // All End points
  dealerRequest: "/dealer-request",
  uploadFile: "/upload-api",

  // S3 File path
  BUCKETNAME: isEnableProd ? "dealer-portal-doc" : "dealer-portal-doc-qa",
  INVENTORY_FOLDER: "inventory",
  BOM_FOLDER: "bom",
  DOWNLOADURL: "https://s3.ap-south-1.amazonaws.com/",

  // S3 Configuration
  region: "ap-south-1",
  accessKeyId: "AKIA3JJS6YMBBHVLVRXA",
  secretAccessKey: "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"
};
//https://tph-doc-prod.s3.ap-south-1.amazonaws.com/scstatement/43434.pdf
