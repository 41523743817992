import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  TextField,
  Button,
  Grid,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { validateRegistrationFields } from "../util/validator";
import AllString from "../ConstFiles/StringConstant";
import callServer from "../Apiservice/ApiExecutor";
import LoadingDialog from "../CommonComponents/LoadingDialog";
import ApiEndPoint from "../Apiservice/ApiName";
import { takeResponseParsingDecision } from "../Apiservice/ApiResponseHttpParser";
import { logger } from "../util/CommonFunction";
import AlertDailog from "../CommonComponents/AlertDialog";
import dstock from "../../src/assets/images/dstock_icon.jpeg";
import registerLine from "../../src/assets/images/registerLine.svg";
import emailIcon from "../../src/assets/images/email_icon.svg";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  dstockmedia: {
    width: "250px",
    height: "54px",
    marginTop: "34px",
    //  marginLeft: "173px",
    // marginRight: "173px",
    //backgroundSize: "auto",
    display: "inline-flex"
  },
  textField: {
    width: "260px",
    marginTop: "6px",
    borderRadius: "8px",
    boxShadow: "inset 0 0 1px 0 #20dada",
    border: " 0.1px #2fbea8",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    }
  },
  textFieldBrand: {
    width: "100%",
    marginTop: "6px",
    borderRadius: "8px",
    boxShadow: "inset 0 0 1px 0 #20dada",
    border: " 0.1px #2fbea8",

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#20dada"
    }
  },
  register_dealer: {
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  button: {
    marginTop: 20,
    width: "340px",
    height: "50px",
    // marginLeft: "123px",
    // marginRight: "127px",
    fontWeight: 600,
    borderRadius: "8px",
    color: "#FFFFFF",
    backgroundColor: "#2fbea8",
    "&:hover": {
      backgroundColor: "#2fbea8"
    },
    fontFamily: "Montserrat",
    fontSize: "20px",
    textTransform: "none"
  },
  button_txt: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  href_style: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4d54ac"
  },
  line: {
    width: "92px",
    height: "6px",
    //marginLeft: "260px",
    //marginRight: "253px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  email_icon_style: {
    width: "60px",
    height: "60px",
    marginTop: "63px",
    backgroundSize: "auto",
    display: "inline-flex"
  },
  thanku_for_register: {
    marginTop: "30px",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  our_representative_style: {
    marginTop: "26px",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#504a67"
  }
});

function RegisterLoginCard(props) {
  const [values, setValues] = useState({
    companyName: "",
    contactPerson: "",
    emailId: "",
    mobileNumber: "",
    brand: ""
  });
  const classes = useStyles();

  const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  //const [isRegistedSuccess, setIsRegistedSuccess] = useState(false);

  const [alertMsg, setAlertMsg] = useState("");

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      validateData();
    }
  };

  const handleHrefClick = () => {
    props.onHrefClick(true);
  };

  // Common function for open alert dialog from all function within a class..
  const alertDialogOpen = msg => {
    setIsShowAlertDialog(true);
    setAlertMsg(msg);
  };
  const backToLoginCard = () => {
    props.onHrefClick(true);
  };

  const validateData = () => {
    var msgMy = validateRegistrationFields(
      values.companyName,
      values.contactPerson,
      values.emailId,
      values.mobileNumber,
      values.brand
    );
    if (msgMy.length > 0) {
      alertDialogOpen(msgMy);
      return;
    }

    if (/^[6-9]{1}[0-9]{9}$/.test(values.mobileNumber)) {
      console.log("");
    } else {
      alertDialogOpen("Please Enter Valid mobile number");
      return;
    }

    setIsShowLoading(true);

    var payload = {
      requestType: "VISITER_REGISTRATION",
      companyName: values.companyName,
      contactPerson: values.contactPerson,
      emailId: values.emailId,
      mobileNumber: values.mobileNumber,
      brand: values.brand
    };

    callServer(
      ApiEndPoint.dealerRequest,
      JSON.stringify(payload),
      "POST",
      apiResponse => {
        let responseData = takeResponseParsingDecision("POST", apiResponse);
        logger("ApiRes -- ", JSON.stringify(responseData));
        setIsShowLoading(false);
        if (responseData === undefined || responseData === null) {
          alertDialogOpen(AllString.someWentWrong);
          return;
        }

        setIsShowLoading(false);
        if (responseData.statusCode === 400) {
          alertDialogOpen(AllString.enq_data_exists);
          return;
        } else if (responseData.statusCode !== 200) {
          alertDialogOpen(responseData.message);
          return;
        }

        // setIsRegistedSuccess(true);
        props.onRegisteredSuccessEnq(true);
      }
    );
  };
  return (
    <div>
      <CardMedia
        className={classes.dstockmedia}
        image={dstock}
        title="The Purchase House"
      />
      <div>
        <CardContent>
          <Typography className={classes.register_dealer}>
            Register as Dealer & Distributor
          </Typography>
        </CardContent>
        <CardMedia
          className={classes.line}
          image={registerLine}
          title="The Purchase House"
        />
        <div style={{ marginRight: 25, marginLeft: 25, marginTop: 40 }}>
          <Grid container className={classes.root} spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                className={classes.textField}
                id="outlined-basic"
                placeholder="Company name"
                variant="outlined"
                color="secondary"
                value={values.companyName}
                onChange={handleChange("companyName")}
                onKeyPress={handleKeypress}
                autoFocus
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Contact person "
                variant="outlined"
                value={values.contactPerson}
                onChange={handleChange("contactPerson")}
                onKeyPress={handleKeypress}
                className={classes.textField}
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                className={classes.textField}
                id="outlined-basic"
                placeholder="Email-ID "
                variant="outlined"
                color="secondary"
                value={values.emailId}
                onChange={handleChange("emailId")}
                onKeyPress={handleKeypress}
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Phone no."
                variant="outlined"
                //type="number"
                type="tel"
                value={values.mobileNumber}
                onChange={handleChange("mobileNumber")}
                onKeyPress={handleKeypress}
                className={classes.textField}
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Brand you deal in "
                variant="outlined"
                value={values.brand}
                onChange={handleChange("brand")}
                onKeyPress={handleKeypress}
                className={classes.textFieldBrand}
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.button}
            variant="contained"
            onClick={validateData}
          >
            <span className={classes.button_txt}>Register</span>
          </Button>

          <div style={{ marginTop: "20px" }}>
            <a
              className={classes.href_style}
              href={"#"}
              onClick={handleHrefClick}
            >
              Already a member?
            </a>
          </div>
        </div>
      </div>
      {/*) : (
        <div>
          <Divider variant="middle" style={{ marginTop: 30 }} />

          <CardMedia
            className={classes.email_icon_style}
            image={emailIcon}
            title="The Purchase House"
          />

          <Typography className={classes.thanku_for_register}>
            Thank you for registering with us
          </Typography>
          <Typography className={classes.our_representative_style}>
            Our representative will get back to you shortly with further
            process.
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            onClick={validateData}
            style={{ marginTop: "24px" }}
          >
            <span className={classes.button_txt}>Back To Login</span>
          </Button>
      </div>
      )}*/}
      <AlertDailog
        isShowAlertDialog={isShowAlertDialog}
        onOkClickButton={() => setIsShowAlertDialog(false)}
        alertMsg={alertMsg}
        // title={isRegistedSuccess ? AllString.enq_success_title : undefined}
      />
      <LoadingDialog showLoading={isShowLoading} />
    </div>
  );
}

export default RegisterLoginCard;
