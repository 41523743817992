import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  CardHeader,
  Divider,
  Box,
  Grid,
  Button,
  Drawer
} from "@material-ui/core";
import LoadingDialog from "../CommonComponents/LoadingDialog";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import ApiEndPoint from "../Apiservice/ApiName";
import { downloadHistoryColumn } from "../util/TableColumn";
import PageFooterQueryText from "../CustomClasses/PageFooterQueryText";
import StringConstant from "../ConstFiles/StringConstant";
import AlertDailog from "../CommonComponents/AlertDialog";
import callServer from "../Apiservice/ApiExecutor";
import { takeResponseParsingDecision } from "../Apiservice/ApiResponseHttpParser";
import AllString from "../ConstFiles/StringConstant";
import { logger } from "../util/CommonFunction";
import Header from "../header";
import ExcellViewer from '../CustomClasses/ExcellViewer'

const StyledTableCell = withStyles(theme => ({
  head: {
    //backgroundColor: "#4d54ac",
    backgroundColor: "rgba(0,0,255,0.22)",
    fontFamily: "Montserrat",
    fontSize: "17px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    //color: theme.palette.common.black,
    fontSize: "20px",
    // opacity: 0.22,
    height: 10
  },
  body: {
    fontSize: 1
  }
}))(TableCell);

/*function createData(carrierLegalName, carrierId) {
  return { carrierLegalName, carrierId };
} */

const tableBodyData_ = [
  {
    date: "23/05/2020",
    time: "12.45 PM",
    brand: "Cummins India Ltd",
    downloadLink: ""
  },
  {
    date: "24/05/2020",
    time: "11.45 PM",
    brand: "Cummins India Ltd",
    downloadLink: ""
  },
  {
    date: "25/05/2020",
    time: "10.45 PM",
    brand: "Cummins India Ltd",
    downloadLink: ""
  }
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  typography_style: {
    marginTop: "40%",
    padding: 15
  },
  parentCardStyle: {
    width: "85vw",
    height: "90vh",
    display: "block",
    marginRight: "65%",
    transitionDuration: "0.39",
    borderRadius: 1,
    // backgroundColor: "#FFFFFF"
    backgroundColor: "rgb(243,246,254)"
    //color: theme.palette.primary.contrastText
  },
  cardHeaderStyle: {
    width: "593px",
    height: "27px",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 600,
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#241d41"
  },
  parentGrid: {
    marginTop: 12,
    backgroundColor: "rgb(243,246,254)"
    //  marginLeft: 10,
    //  marginRight: 20
  },
  boxStyle: {
    paddingLeft: "29px",
    paddingRight: "29px",
    borderRadius: 5
  },
  rowCellStyle: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#1b0c39"
  }
});


//const file = 'http://file-examples-com.github.io/uploads/2017/02/file_example_XLSX_10.xlsx'
const file = 'https://dealer-portal-doc-qa.s3.ap-south-1.amazonaws.com/inventory/1_Siemens_Inventory_1614005333592.xlsx'
const type = 'xlsx'

//var tableBodyData = [];
export default function TestViewer(props) {
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const [tempUrl, setTempUrl] = useState("");

  useEffect(() => {
    //fetchDownloadHistory();
    setTempUrl(file)
  }, []);

 const onError=(e)=> {
    logger.logError(e, 'error in file-viewer');
  }

  const clickFire = ()=>{
    setopen(true)
  }
  return (
      <div>
          <Button onClick={clickFire}>Click Me</Button>
   
       <Drawer
        anchor={"right"}
        open={open}
        onClose={() => {
          setopen(false);
        }}
      >
        <ExcellViewer
          setopen={setopen}
          url={tempUrl}
        />
      </Drawer>
      
      </div>
  );
    
}
