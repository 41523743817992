import AllString from "../ConstFiles/StringConstant";
export const validateText = text => {
  if (/^[a-zA-Z.\- ]{1,}$/.test(text)) {
    return true;
  }
  return false;
};
export const validatenumber = text => {
  if (Number(text)) {
    return true;
  }
  return false;
};

export const validateOtp = otp => {
  if (/^[0-9]{6}$/.test(otp)) {
    return true;
  }
  return false;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateBlankField = data => {
  if (data.length) return true;
  else return false;
};

export const validateAllInputFields = (userId, password) => {
  if (userId.length === 0) {
    return AllString.enterUserID;
  } else if (password.length == 0) {
    return AllString.enterPassword;
  } else {
    return "";
  }
};

export const validateRegistrationFields = (
  companyName,
  contactPerson,
  emailId,
  mobileNumber,
  brand
) => {
  if (companyName.length === 0) {
    return AllString.companyName;
  } else if (contactPerson.length == 0) {
    return AllString.contactPerson;
  } else if (emailId.length == 0) {
    return AllString.emailId;
  } else if (mobileNumber.length == 0) {
    return AllString.mobileNumber;
  } else if (brand.length == 0) {
    return AllString.brand;
  } else {
    return "";
  }
};
